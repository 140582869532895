import React from 'react';

import { TextRegularBold } from '../shared/typography';
import styled from '@emotion/styled';
import { theme } from '../shared/theme';

const StyledBtn = styled.button`
border: none;
border-radius: 3px;
background:  ${theme.colors.greyLight.grey25};
height: 25px;
width: 25px;
font-size: 16px;
text-align: center;
cursor: pointer;
&:hover{
  background:  ${theme.colors.greyLight.grey15};
}
`
const Container = styled.div`
  display: flex;

` 

type Props = {
  onIncrement: () => void;
  onDecrement: () => void;
  onChange: (e: any) => void
  disabled: boolean;
  value: string;
};

const NumericInput: React.FC<Props> = ({
  onDecrement,
  onIncrement,
  disabled,
  value,
}: Props) => {
  return (
    <Container>
      <StyledBtn disabled={disabled} aria-label="Decrement" onClick={onDecrement}>
        -
      </StyledBtn>
      <TextRegularBold style={{margin: '0 0.5rem'}}>{value}</TextRegularBold>
      <StyledBtn disabled={disabled} aria-label="Increment" onClick={onIncrement}>
        +
      </StyledBtn>
    </Container>
  );
};

export default NumericInput;
