import { BtnBlack, BtnWhiteOut } from '../components/shared/button'
import { Col, Divider, Row, Spin } from 'antd'
import { H1Bold, H2, H3Bold, Text2xsmall, Text2xsmallBold, TextRegularBold, TextSmall, TextSmallBold } from '../components/shared/typography'
import { Link, navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'

import LineItem from '../components/cart/cart-item'
import Seo from '../components/Seo'
import { StoreContext } from '../context/store-context'
import { StyledContent } from '../components/layout/layout'
import { formatPrice } from '../components/shared/utils'
import styled from '@emotion/styled'
import useWindowWidth from '../components/shared/useWindowSize'

const EmptyCart = styled.div`
  margin: 1rem;
`

const PriceCol = styled(Col)`
  display: flex;
  justify-content: end;
  @media (max-width: 760px) {
    margin-left: 1rem;
    padding-bottom: 2rem;
  }
`

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
`

declare global {
  interface Window {
    verifyIdCheckout: (params: any) => void
    all_buttons_checker: () => void
    verifyIdAllPages: (pluginKey: string, minAge: number, startButton: boolean) => void
    verifyAgeProcessGet: () => Promise<{ age: number }> // Add this line to declare verifyAgeProcessGet
  }
}

const Cart: React.FC = () => {
  const { checkout } = useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0
  const width = useWindowWidth()
  const [isLoading, setIsLoading] = useState(false)
  const [validationActive, setValidationActive] = useState(false)
  const [ageVerified, setAgeVerified] = useState(false)

  // Load and initialize the script in a useEffect hook
  useEffect(() => {
    const SCRIPT_ACTIVED = validationActive
    const SCRIPT_ACTIVED_ALL_PAGES = true
    const MINIMUM_AGE_FOR_ALL_PAGES = 18
    const PLUGIN_KEY = '4cc11e86-9a46-44f2-ab3d-f8da9e3a27aa'

    // Define the script loading function
    function loadVerifyIDScript() {
      // Check if the script is already loaded
      if (document.getElementById('verifyid-script')) return

      var script = document.createElement('script')
      script.id = 'verifyid-script'
      script.src = 'https://app.verifyid.dk/static/data/shopify/verifyid1_02.js?v=1.0.0'
      script.async = true
      document.head.appendChild(script)

      script.onload = async () => {
        console.log('External script loaded.')
        if (SCRIPT_ACTIVED) {
          // Call functions from the loaded script

          if (SCRIPT_ACTIVED_ALL_PAGES && typeof window.verifyIdAllPages === 'function') {
            const l = await window.verifyIdAllPages(PLUGIN_KEY, MINIMUM_AGE_FOR_ALL_PAGES, true)
          }

          if (typeof window.all_buttons_checker === 'function') {
            window.all_buttons_checker()
          }

          if (typeof window.verifyAgeProcessGet === 'function') {
            window
              .verifyAgeProcessGet()
              .then((verifyAgeData) => {
                if (verifyAgeData) {
                  const userAge = verifyAgeData.age
                  const isAllowedToNavigate = userAge >= 18

                  if (isAllowedToNavigate) {
                    setAgeVerified(true)
                  } else {
                    setAgeVerified(false)
                  }
                }
              })
              .catch((error: any) => {
                console.error('An error occurred:', error)
              })
              .finally(() => {
                setIsLoading(false)
                setValidationActive(false)
              })
          }
        }
      }

      script.onerror = () => console.error('Failed to load the checkout script')
    }

    loadVerifyIDScript()

    // Return a cleanup function to remove the script on component unmount
    return () => {
      const script = document.getElementById('verifyid-script')
      if (script) {
        document.head.removeChild(script)
      }
    }
  }, [validationActive])

  useEffect(() => {
    if (typeof window.verifyAgeProcessGet === 'function') {
      window
        .verifyAgeProcessGet()
        .then((verifyAgeData) => {
          if (verifyAgeData) {
            const userAge = verifyAgeData.age
            const isAllowedToNavigate = userAge >= 18

            if (isAllowedToNavigate) {
              setAgeVerified(true)
            } else {
              setAgeVerified(false)
            }
          }
        })
        .catch((error: any) => {
          console.error('An error occurred:', error)
        })
        .finally(() => {
          setIsLoading(false)
          setValidationActive(false)
        })
    } else {
      console.error('verifyAgeProcessGet function is not available.')
      setIsLoading(false)
    }
  })

  const handleCheckout = () => {
    setIsLoading(true)
    setValidationActive(true)

    // Call the external script's function
    if (typeof window.verifyIdCheckout === 'function') {
      window.verifyIdCheckout({ key: 'value' }) // Pass appropriate parameters to the function
    }

    if (ageVerified) {
      var url = checkout.webUrl.replace('mansted-wine.myshopify.com', 'checkout.manstedwine.dk')
      navigate(url)
      setIsLoading(false)
    } else if (!validationActive) {
      setTimeout(() => {
        if (!validationActive) {
          setIsLoading(false)
        }
      }) // Adjust this delay as needed based on verification
    }
  } // Adjust this delay as needed based on verification

  return (
    <StyledContent>
      <Seo title={emptyCart ? 'Din kurv er i øjeblikket tom' : 'Kurv'} description={'Kurv'} />
      <H1Bold style={{ textAlign: 'center', marginTop: '2rem', marginBottom: '3rem' }}>MIN KURV</H1Bold>
      {emptyCart ? (
        <EmptyCart>
          <H2>Din indkøbskurv er tom!</H2>
          <TextSmall>Det ser ud til, at du ikke har fundet noget endnu. Vi forstår det nogle gange er svært at vælge - måske hjælper dette:</TextSmall>
          <Link to="/vine">Vis produkter</Link>
        </EmptyCart>
      ) : (
        <>
          {width > 700 && (
            <Row gutter={20} style={{ width: '100%' }}>
              <StyledCol span={4}></StyledCol>
              <StyledCol span={11}></StyledCol>
              <StyledCol span={3}>
                <TextRegularBold>PRIS</TextRegularBold>
              </StyledCol>
              <StyledCol span={3}>
                <TextRegularBold>ANTAL</TextRegularBold>
              </StyledCol>
              <StyledCol span={3}>
                <TextRegularBold>TOTAL</TextRegularBold>
              </StyledCol>
              <Divider />
            </Row>
          )}
          {checkout.lineItems.map((item: any) => (
            <LineItem item={item} key={item.id} />
          ))}
          <Row justify="space-between" style={{ marginBottom: '8rem', marginTop: '2rem' }}>
            <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 15, order: 1 }} style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              <BtnWhiteOut style={{ width: 'fit-content', marginRight: '1.5rem', marginBottom: '1rem' }} onClick={() => navigate('/vine')}>
                Fortsæt Shopping
              </BtnWhiteOut>
              <BtnBlack
                style={{
                  width: 'fit-content',
                  minWidth: '150px',
                  marginRight: '1.5rem',
                  marginBottom: '1rem',
                  paddingTop: 0,
                  paddingBottom: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
                onClick={handleCheckout}
                disabled={isLoading}
              >
                {isLoading ? <Spin /> : 'Checkout'}
                {ageVerified && <Text2xsmall style={{ color: 'green', margin: 0 }}>Alder verificeret</Text2xsmall>}
              </BtnBlack>
            </Col>
            <PriceCol xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 9, order: 2 }}>
              <Row align="middle">
                <Col>
                  <Row style={{ marginBottom: '0.5rem', height: '30px' }}>
                    <TextSmallBold style={{ marginRight: '2rem', marginBottom: 0, alignSelf: 'center' }}>Total Pris:</TextSmallBold>
                  </Row>
                  <Text2xsmallBold style={{ marginRight: '2rem', marginBottom: '0.5rem' }}>Fragt:</Text2xsmallBold>
                  <Text2xsmallBold style={{ marginRight: '2rem', marginBottom: '0.5rem' }}>Subtotal:</Text2xsmallBold>
                </Col>
                <Col>
                  <Row style={{ marginBottom: '0.5rem', height: '30px' }}>
                    <H3Bold style={{ marginBottom: 0, alignSelf: 'center' }}>{formatPrice(checkout.totalPriceV2.currencyCode, checkout.totalPriceV2.amount)}</H3Bold>
                  </Row>
                  <Text2xsmall style={{ marginBottom: '0.5rem' }}>{checkout.subtotalPriceV2.amount >= 1000 ? 'Gratis' : 'Udregnet ved checkout'}</Text2xsmall>
                  <Text2xsmall style={{ marginBottom: '0.5rem' }}>{formatPrice(checkout.subtotalPriceV2.currencyCode, checkout.subtotalPriceV2.amount)}</Text2xsmall>
                </Col>
              </Row>
            </PriceCol>
          </Row>
        </>
      )}
    </StyledContent>
  )
}

export default Cart
