import React from 'react';

import { Col, Divider, Row } from 'antd';
import { TextRegularBold, TextSmallBold } from '../shared/typography';
import { formatPrice, NavigateToProduct } from '../shared/utils';
import { useContext, useMemo, useState } from 'react';

import { BtnGrey } from '../shared/button';
import { Image } from 'antd';
import NumericInput from './numeric-input';
import { StoreContext } from '../../context/store-context';
import debounce from 'lodash.debounce';
import { getShopifyImage } from 'gatsby-source-shopify';
import styled from '@emotion/styled';
import useWindowWidth from '../shared/useWindowSize';

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-self: center;
`;

const LineItem = ({ item }: any) => {
  const width = useWindowWidth();
  const { removeLineItem, checkout, updateLineItem, loading } =
    useContext(StoreContext);
  const [quantity, setQuantity] = useState(item.quantity);

  const variantImage = {
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  };
  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount),
  );

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity,
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    200,
  );
  // eslint-disable-next-line
  const debouncedUli = React.useCallback((value: any) => uli(value), []);

  const handleQuantityChange = (value: any) => {
    if (value !== '' && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1);
  }

  const image = useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: 'constrained',
        
        width: 160,
        height: 460,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variantImage?.src],
  );

  return (
    <>
      {width > 700 ? (
        <Row gutter={20} style={{ width: '100%' }}>
          <StyledCol span={4}>
            {image && (
              <Image
                style={{ objectFit: 'contain', height: '200px' }}
                key={variantImage?.src}
                src={variantImage?.originalSrc}
                alt={variantImage.altText ?? item.variant.title}
              />
            )}
          </StyledCol>
          <StyledCol
            span={11}
            style={{ flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <Row style={{ width: '100%' }}>
              <TextRegularBold
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  NavigateToProduct(item)
                }
              >
                {item.title}
              </TextRegularBold>
            </Row>
            <BtnGrey onClick={handleRemove} style={{ width: 'fit-content' }}>
              Fjern
            </BtnGrey>
          </StyledCol>
          <StyledCol span={3}>
            <TextSmallBold style={{ margin: 0 }}>{price}</TextSmallBold>
          </StyledCol>
          <StyledCol span={3}>
            <NumericInput
              disabled={loading}
              value={quantity}
              aria-label="Quantity"
              onIncrement={doIncrement}
              onDecrement={doDecrement}
              onChange={(e: any) => handleQuantityChange(e.currentTarget.value)}
            />
          </StyledCol>
          <StyledCol span={3}>
            <TextSmallBold style={{ margin: 0 }}>{subtotal}</TextSmallBold>
          </StyledCol>
          <Divider />
        </Row>
      ) : (
        <Row align="middle">
          <Col span={8}>
            {image && (
               <Image
                style={{ objectFit: 'contain', height: '150px' }}
                key={variantImage.src}
                src={variantImage.originalSrc}
                alt={variantImage.altText ?? item.variant.title}
             />
            )}
          </Col>
          <Col span={16}>
            <TextRegularBold
              style={{ cursor: 'pointer', width: '100%' }}
              onClick={() =>
                NavigateToProduct(item.title)
              }
            >
              {item.title}
            </TextRegularBold>
            <Row justify="space-between" align="middle">
              <Col span={12}>
                <NumericInput
                  disabled={loading}
                  value={quantity}
                  aria-label="Quantity"
                  onIncrement={doIncrement}
                  onDecrement={doDecrement}
                  onChange={(e: any) =>
                    handleQuantityChange(e.currentTarget.value)
                  }
                />
              </Col>
              <Col span={12}>
                <TextSmallBold style={{ margin: 0 }}>{price}</TextSmallBold>
              </Col>
            </Row>
            <BtnGrey onClick={handleRemove} style={{ width: 'fit-content', marginTop: '1rem' }}>
              Fjern
            </BtnGrey>
          </Col>
          <Divider />
        </Row>
      )}
    </>
  );
};

export default LineItem;
